/*==========Variable starts here==========*/

$primary: #00684d;
$bg-white: #fff;
$profile-bg: #f7f7f7;
$bg-gray: #F6F6F6;
$danger: red;
$light: #f7f7f775;

/*==========Variable ends here==========*/

/*==========Mixin starts here==========*/

@mixin flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddd;
  border-style: dashed;
  text-align: center;
  padding: 5rem 1rem;
}

@mixin endflex {
  display: flex;
  justify-content: flex-end;
}


@mixin startflex {
  display:flex;
  justify-content:flex-start;
}

@mixin centerFlex {
  display: flex;
  justify-content: center;
}

@mixin model{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  text-align: center;
  word-wrap: break-word;
  background-color: #fff;
  padding: 20px;
}

@mixin secondryButton{
  background-color: #e0e0e0 !important;
  color: #000 !important;
  text-transform: capitalize !important;
  height:52px;  
}

@mixin warningButton{
  background: #f29810; 
  padding: 5px 10px;
  font-size: 12px; 
  text-transform:capitalize!important;
}

@mixin tableContent{
  
}


/*==========Mixin ends here==========*/
