@import "common";

/*====Common here===*/
// .App{mix-blend-mode: overlay;}

.display_block {
  display: block !important;
}

.word_wrap {
  word-wrap: break-word;
}

.header_store {
  background: white;
  border-radius: 5px;
  color: rgb(0, 104, 77);
}

.MuiContainer-root {
  max-width: 100vw !important;
  // padding-right: 5px
}

.order_status:hover {
  cursor: default;
  box-shadow: none;
}

.table_cell_padding {
  // padding:0px 0px !important;
}

.text_transform {
  text-transform: capitalize !important;
  font-size: 12px !important;
}

.no_data_text_align_center {
  td:last-child div:last-child {
    width: auto !important;
    // text-align: center;
  }
}

.cross_icon {
  color: red;
  font-size: 16px !important;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

/*====Common here===*/

/*====Bulk Product Upload here===*/
.primary {
  color: $primary !important;
}

.download-upload-icon {
  font-size: 3.5rem !important;
  cursor: pointer;
}

.bg-white {
  background-color: $bg-white;
}

.profile-bg {
  background-color: $profile-bg;
}

.order_date {
  margin-left: 22%;
}

.bg-gray {
  background: $bg-gray;
}

.fw {
  font-weight: bold !important;
}

.textarea {
  width: 50% !important;
  height: 100px !important;
  border: 1px solid #8a7e7e;
}

.text_wrap_data {
  text-overflow: ellipsis !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  padding-right: 36px;
}

.menu_item {
  max-width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  white-space: nowrap;
  max-width: 100px;
  display: inline-block;
  overflow: hidden !important;
  line-height: 35px !important;
  text-overflow: ellipsis !important;
}

.divider_margin {
  margin: 0px 5px !important;
}

.mt_300 {
  margin-top: 200px;
}

.menu_margin {
  margin-top: 48px;
}

.menu_width {
  width: 220px;
}

.main {
  min-height: 100%;
}

.footer {
  text-align: center;
  background-color: #f9f9f9 !important;
  height: 50px !important;
  line-height: 50px !important;
  margin-top: 100px !important;
  // position:absolute;
  // bottom:0px;
  // width:100%;
}

.float_right {
  float: right;
}

.maintenance_date {
  display: flex;
  padding: 10px 0px;

  h4 {
    font-size: 30px;
  }

  .maintenance_mode_to {
    font-size: 30px !important;
  }
}

.margin-top {
  margin-top: 9px !important;
}

.table_right {
  float: right;
  margin-top: 30px;
}

.flter_icon {
  color: #757575;
  margin-right: 20px;
  cursor: pointer;
}

.text_center {
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

.order_track_link {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  text-decoration: none;
}

textarea:focus-visible {
  border: 1px solid #00684d;
  outline: none;
}

.display_flex {
  display: flex;
}

.manage_btn {
  text-transform: capitalize !important;
  display: block !important;
}

.color-grey {
  color: grey !important;
  font-size: 11px !important;
}

$color_1: #fff;

.manage_inventory {
  .qua_input {
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 6px 12px !important;
      text-align: right;
    }
  }

  .css-1l5fbux-MuiFormLabel-root-MuiInputLabel-root {
    max-width: calc(100% - 57px) !important;
  }
}

.light-gray {
  background: $light;
  padding: 20px 0px 20px 0px;
  margin-bottom: 10px;
}

.name_menu {
  padding: 0px 14px;
  height: 35px;
  max-width: auto;
}

p,
span {
  font-size: 14px !important;
}

h6 {
  font-size: 16px !important;
}

.text-danger {
  color: $danger;
}

.manage-inventry-input {
  width: 86px;
}

.download-btn {
  min-width: 120px !important;
}

.template-border-text {
  @include flexbox;
}

.Product-template-download-border {
  @include flexbox;
  color: $primary;
}

.disableTemplateDownload {
  color: rgba(0, 0, 0, 0.30);
}

.template-download-border {
  @include flexbox;
  color: $primary;
  border-left: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.upload-img {
  width: 50px;
}

.other-img {
  width: 50px;
  margin-right: 5px;
}

.file-border {
  display: block;
  border: 2px solid #ddd;
  border-style: dashed;
  text-align: center;
  padding: 3rem 1rem;

  .upload-btn {
    display: block;
    margin: 20px auto;
    padding: 5px 20px;
  }
}


.btn_custom_inline {
  margin-left: 6px !important;
  height: 55px !important;
}

.product_text_area {
  background-color: white;
}

// .oms_table
//   {
//     overflow-y: auto; //or hidden if you don't want horizontal scrolling
//     overflow-y: auto;
//     scrollbar-color: transparent transparent;
// }
.card_content {
  p {
    font-size: 14px !important;
  }
}

.bg-card {
  background-color: #acacac 12%;
}

.oms-tab-filters {
  padding: 5px !important;
  height: 40px !important;
  // padding: 10px;
  width: 100px;
}

.oms-shipped-text {
  padding: 6px !important;
  // margin-left: 19px !important;
}

.oms_table {
  .css-hrwxsz-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 250px !important;
  }

  .oms_table_body {
    border-bottom: 1px solid #ddd !important;
  }

  .css-2lu2eg-MuiTableCell-root::first-line {
    border-bottom: 1px solid #ddd !important;
  }

  .css-19gb7ar-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px !important;
    color: #000 !important;
  }

  .css-14f76fm-MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto;
    font-size: 12px;
  }

  .css-11p575s-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #bdbdbd;
    border-color: rgb(102 106 105 / 27%);
  }

  .css-1b008ma-MuiDivider-root {
    margin-bottom: 0 !important;
  }

  .css-i4bv87-MuiSvgIcon-root {
    margin-right: 2px !important;
    font-size: 1.4rem;
  }

  .css-i4bv87-MuiSvgIcon-root {
    color: #1c171785 !important;
  }

  .packing_slip_btn {
    @include warningButton;
  }

  .oms_head_btn {
    text-transform: capitalize !important;
    font-size: 12px !important;
  }
}

.oms_button_list_dropdown {
  width: 0px;
  background-color: #e0e0e0 !important;
}

.exhibition_btn {
  padding: 5px 20px;
  text-transform: capitalize !important;
  margin: 0px 4px !important;
}

// Oms starts here

.maintanance_page {
  max-width: 1200px;
  margin: 100px auto;

  img {
    max-width: 200px;
  }

  .under_maintanance {
    padding-top: 100px;

    h1 {
      font-size: 96px;
      font-weight: bold;
    }

    h3 {
      font-size: 36px;
    }
  }

  .under_img {
    max-width: 600px;
    width: 100%;
  }
}

.oms_table_box {
  display: block;
  width: 295px;
  margin-bottom: 10px !important;
  word-wrap: break-word;
}

.oms_table {
  display: table;
  overflow-x: auto;
  width: 100%;

  .css-10c6lse-MuiTableRow-root {
    vertical-align: top;
  }

  .oms_head {
    background-color: #f0f2f2;
  }

  .table_border:last-child td {
    border-bottom: none !important;
  }

  th {
    padding: 10px 7px;
    width: 220px;
    vertical-align: middle;
  }

  td {
    min-width: 300px;
    padding: 10px 5px;
  }

  img {
    width: 40px;
  }
}

.oms_table th div {
  width: 220px;
}

.table_bottom {
  display: flex;
}

.oms_unshipped_filter {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 14.5px 14px !important;
  }

  .unshipped_filter_button {
    @include secondryButton;
  }

  .table_cell_margin {
    margin: 10px 0px !important;
  }
}


/*=====All tables css starts here=====*/


/*=====All tables Buttons starts here=====*/

.report_button {
  display: flex !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  margin-right: 4px !important;
}

/*=====All tables Buttons ends here=====*/
// .tss-1x5mjc5-MUIDataTable-root .datatables-noprint{
//   width:100%!important;
// }

#input_right {
  text-align: right !important;
}

.text_right {
  text-align: right !important;
}

.first_table_cell_small_width div {
  table th:first-child div {
    width: 50px !important;
  }

  table td:last-child div {
    width: auto !important;
  }

  table th:last-child div {
    min-width: 70px !important;
  }

  table td:first-child div {
    width: auto !important;
  }
}

.sales_history_first_table_cell_small_width {

  table th:first-child div {
    width: 100px !important;
  }

  table th:last-child div {
    min-width: 70px !important;
  }

  table td:first-child div {
    width: auto !important;
  }

}

.history_table {

  td>div {
    width: 200px;
    word-wrap: break-word;
  }

  th>div {
    width: 200px;
    word-wrap: break-word;
  }
}

.exhibition_table {
  td>div {
    width: 300px;
    word-wrap: break-word;
  }

  th>div {
    width: 300px;
    word-wrap: break-word;
  }
}

.shipment_product_table_box {
  width: 300px !important;
}

.shipment_product_table_box {
  width: 300px !important;
}

.shipment_product_table {
  .table_head {
    background-color: #f0f2f2;
    vertical-align: middle;
  }

  thead {
    background-color: #f0f2f2;

    tr>th>div {
      width: 150px;
      vertical-align: middle;
    }
  }

  th {
    word-wrap: break-word;
  }

  td>p {
    width: 150px;
    word-wrap: break-word;
    vertical-align: middle;
  }

  td {
    width: 150px;
    word-wrap: break-word;
    vertical-align: middle;
  }

  td>div {
    width: 150px;
    word-wrap: break-word;
    vertical-align: middle;
  }
}

.product_list_table {
  margin: 50px 0px;

  .text_right {
    text-align: right;
  }

  th>span {
    width: 178px;
    word-wrap: break-word;
  }

  th>div {
    word-wrap: break-word;
    width: 178px;
  }

  td>div {
    width: 174px;
    word-wrap: break-word;
  }
}

.product_text_right {
  text-align: right;
  // width:75px!important
}

.image_list_table {
  .text_right {
    text-align: right;
  }

  th {
    width: 160px;
    word-wrap: break-word;
  }

  th div {
    width: 160px;
    word-wrap: break-word;
  }

  td {
    width: 160px;
    word-wrap: break-word;
  }

}

.image_upload_list_table_container {

  table {
    width: 100%;
    // height: 100%;
    max-height: 480px;
    border-collapse: collapse;
    table-layout: fixed;
    align-content: center;
    vertical-align: middle;
    /* Ensures columns remain equal */
  }

  tr:first-child th:first-child,
  td:first-child {
    width: 7%;
  }

  tr:first-child th:last-child,
  td:last-child {
    width: 7%;
    padding: 2px 0px 0px;
  }

  tr:first-child th:nth-child(4),
  td:nth-child(4) {
    width: 23%;
  }

  tr:first-child th:nth-child(4),
  td:nth-child(5) {
    width: 20%;
    align-content: center;
    vertical-align: middle;

    // @-moz-document url-prefix() {
    //     padding-bottom: 15px;
    // }
  }

  tr:first-child th:nth-child(6),
  td:nth-child(6) {
    text-align: center;
  }


  th,
  td {
    text-align: left;
    word-wrap: break-word;
    width: 16%;
    padding: 5px 5px;
    align-content: center;
    vertical-align: middle;
  }

  thead tr:first-child th {
    align-content: flex-start;
    vertical-align: top;
  }
}

.image_upload_list_table_container {
  height: 100%;
  padding-right: 0px !important;
}

.image_upload_list_table_container>div:first-child {
  height: 100%;
}


.minimum_column {
  .text_right {
    text-align: right;
  }

  td>div {
    width: 160px;
    word-wrap: break-word;
  }

  th>div {
    width: 160px;
    word-wrap: break-word;
  }
}


.confirm_table {
  margin: 50px 0px;

  .text_right {
    text-align: right;
  }

  th>div {
    word-wrap: break-word;
  }

  td>p {
    width: 200px;
    word-wrap: break-word;
  }

  td>div p {
    width: 200px;
    word-wrap: break-word;
  }
}

.refund_table_page {
  margin: 50px 0px;

  .text_right {
    text-align: right;
  }

  th>div {
    word-wrap: break-word;
    width: 200px;
  }

  td>div {
    width: 190px;
    word-wrap: break-word;
    margin-top: -15px;
  }

  td>div p {
    width: 185px;
    word-wrap: break-word;
    vertical-align: middle;
  }

  tr:last-child {
    border-bottom: none;
    vertical-align: middle;
  }
}

.store_table {
  td>div {
    width: 200px;
    word-wrap: break-word;
  }

  td {
    width: 200px;
  }

  th {
    width: 200px;
    font-weight: 600;
    word-wrap: break-word;
  }

  th>div {
    width: 200px;
    font-weight: 600;
    word-wrap: break-word;
  }
}

.report_table {
  margin: 50px 0px;

  .text_right {
    text-align: right;
  }

  th>div {
    width: 300px;
    word-wrap: break-word;
  }

  td>div {
    width: 300px;
    word-wrap: break-word;
  }

  td>div:last-child {
    width: 100% !important;
  }
}


.common_table {
  margin: 50px 0px;

  .text_right {
    text-align: right;
  }

  th {
    width: 130px;
    word-wrap: break-word;
  }

  th>div {
    width: 130px;
    word-wrap: break-word;
  }

  td {
    width: 130px;
    word-wrap: break-word;
  }

  td>div {
    width: 150px;
    word-wrap: break-word;
  }

  th>span {
    width: 170px;
    word-wrap: break-word;
  }

  td div:last-child {
    width: auto !important;
  }

}

.refunded_table td {
  min-width: 340px !important;
}

.download_button {
  width: 123px;
}

.download_excel {
  width: 123px;
  margin-top: 15px !important;
}

/*=====All tables css starts here=====*/



.logo-img {
  display: flex;
  margin: 100px auto;
  width: 220px;
}

.profileInfo {
  color: #278396;
}

.profileInfo:hover {
  color: #cd683a;
  text-decoration: underline;
}

.logo-content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.logo-img {
  display: flex;
  margin: 100px auto;
}

.logo-content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.float-rt {
  float: right;
}

.ragistration_box>div {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.special_category_box>div {
  padding: 20px;
  margin-top: 10px;
}

.border-size {
  border: 1px solid #c6cbcb86;
  padding: 20px;
  border-radius: 10px;
}

.ship-border {
  border-bottom: 2px solid rgb(230, 222, 222);
}

.shoping_information {
  border: 1px solid #000;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 10px;
  margin-top: 5px;

  p {
    padding: 2px 0px;
  }
}

.confirm_shipment_bg {
  background-color: #f0f2f2;
  padding: 15px;
  margin-bottom: 10px;
}

.paper_width {
  width: 200px !important;
  margin: 0px auto;
}

.refund_box {
  background-color: #f0f2f2;
  // padding: 15px;
  margin-bottom: 1px;
  border-radius: 5px;
  border: 1px solid gray;
}

.refund_table {
  height: 44px !important;
}

.refund_card-margin {
  margin-top: 93px !important;
}

.refund_product_head {
  background-color: #f0f2f2;
}

.refund_border {
  border: 1px solid #c4c4c4;
}

.refund_border_head {
  border: 1px solid #c4c4c4;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.refund_cancel_botton {
  margin-top: 4px !important;
}

// .refund_submit_botton {
//   width: 142% !important;
// }

.text_margin {
  margin-top: 10px !important;
}

.refund_button {
  display: flex !important;
  justify-content: flex-start !important;
}

.refund_head {
  display: flex !important;
  justify-content: flex-start !important;

  p {
    margin: 10px 20px 10px 0px !important;
  }

  .primary {
    color: $primary !important;
  }
}

.cancel_order_msg {
  font-size: 17px !important;
}

// @media screen and (max-width:599px) {
//   .refund_product_head{display:none !important;}
// }
.refund_box_padding {
  padding-bottom: 20px;
}

.refund_text {
  color: #00684d;
}

.refunds_head_border {
  border: 1px solid #acacac;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.refunds_product_head {
  border: 1px solid #acacac;
}

.refund_botton {
  margin-top: 2px !important;
}

.infromation_icon {
  background-color: green;
}

.refund_img {
  width: 90.75px !important;
  height: 90.75px !important;
}

.redund_order_bg {
  // background-color: #f0f2f2;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #00684d;
  margin-left: 14px !important;
  border-left: 10px solid #00684d;

  .info_button {
    @extend .primary;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

  .clear_button {
    cursor: pointer;
    float: right;
  }
}

.confirm_shipment {
  background-color: #fff;
}

.product_detail-head {
  border: 1px solid #000;

  div {
    padding: 10px;
  }

  div:last-child {
    border-right: unset;
  }
}

.oms_button_filter {
  background-color: #000 !important;
  color: #e0e0e0 !important;
  text-transform: capitalize !important;
  height: 33px;
  border-radius: 30px !important;
  font-size: 0.65rem !important;
}

.product_detail {
  p {
    padding: 4px 0px;
  }

  .product_detail-head {
    padding: 10px;
  }

  .product_detail-body>div:first-child {
    border-left: 1px solid #000;
  }

  .product_detail-body>div:last-child {
    border-right: 1px solid #000;
  }

  .product_detail-body>div {
    padding: 10px;
    border: 1px solid #000;
    border-left: unset;
    border-top: unset;
  }

  .total_order {
    span {
      float: right;
    }

    h6 {
      text-align: right;
      font-size: 14px !important;
      color: #878686;
    }

    .border_bottom {
      border-bottom: 1px solid #ddd;
    }
  }
}

#packing_slip {
  .border-top {
    border-top: 1px solid #000;
  }

  @media print {
    .page_break {
      page-break-after: always;
      break-after: always;
    }
  }
}

.ragistration_box input {
  background-color: #fff;
}

.scrollable-input-parent-padding .MuiOutlinedInput-root {
  padding: 0 14px !important;
}

.scrollable-input-parent-padding .MuiOutlinedInput-input {
  padding-left: 0px !important;
}

.fix_width_button {
  max-width: 576px !important;
  margin: 0px auto !important;
  width: 100%;
}

.space-between {
  background: $bg-gray;
  display: flex;
  justify-content: space-between;
}

.forgot-text {
  color: #a7a7a7;
  cursor: pointer;
  display: inline-block;
  float: right;
}

.reports-border-bottom {
  border-bottom: 1px solid #ddd;
}

.report-carrd-title {
  text-align: right;
}

.report_card {
  height: 120px;
}

.report-card-number {
  font-size: 18px !important;
}

.test {
  cursor: pointer;
}

.oms_tab {
  text-transform: capitalize !important;
  // border-bottom: 10px !important;
  // border-color: #f7f7f775 !important;
  border-bottom: 1px solid #a7a7a7 !important;
  margin-right: 20px !important;
}

// .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
//   box-shadow: none !important;
//   overflow-y: hidden !important;
// }

/*====Bulk Product Upload ends here===*/

/*====image Upload starts here===*/

.wd_100 {
  width: 100%;
}

.wd_100_btn {
  width: 100px;
}

.image-upload {
  position: relative;
  display: inline-block;

  img {
    width: 50px;
    height: 100%;
    // margin-right: 5px;
  }

  &:hover {
    .delete-icon {
      display: block;
    }
  }
}

// .delete-icon {
//   position: absolute;
//   right: 0;
//   top: 0;
//   display: none;
//   a {
//     position: absolute;
//     z-index: 2;
//     background: #f40404;
//     border-radius: 50%;
//     color: $color_1;
//     margin: -8px 0px 0px -16px;
//     transition: all 0.5 ease;
//     cursor: pointer;
//     opacity: 1;
//     width: 15px;
//     height: 15px;
//     line-height: 20px;
//     text-align: center;
//     transition: all 0.5s ease;
//   }
// }

.flex-end {
  @include endflex;
}

.flex_start {
  @include startflex;
}

.mange-order-checkbox {
  position: relative;
  top: -4px;
}

/*====image Upload ends here===*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

.upload_image_main th {
  width: 300px;
  /* overflow-x: hidden; */
  // white-space: nowrap;
  word-wrap: break-word;

}

.upload_image_main .css-188tcqr-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
}

.oms_text_margin {
  margin-right: 5px !important;
  margin-top: -2px !important;
  font-weight: 600;
  font-size: 15px !important;
}

.refund_order_text_color {
  color: red;
}

.oms_confirm {
  @extend .bg-gray;
  padding: 10px;
  margin-top: 20px;

  .cancel_card {
    background-color: #fff;
    border: 2px solid #f0f2f2;
    display: flex;
    padding: 20px;

    table::first-line {
      border-bottom: none;
    }

    img {
      width: 120px;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .cancel_select {
    @extend .bg-gray;
    height: 30px;
    width: 200px;
  }
}

.confirm_card,
.ship_card {
  border: 2px solid #f0f2f2;
  padding: 20px;
  min-height: 220px;

  p {
    width: 100%;
    padding: 4px 0px;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  .flex_item {
    @extend .display_flex;
  }
}

/*====File Uploading===*/
.upload_file_box3 {
  border: 2px dashed #ddd;
  position: fixed;
  bottom: 318px;
  right: 10px;
  width: 400px;
  z-index: 9;

  p {
    font-size: 1rem !important;
    margin-bottom: 1rem;
  }

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root:hover:not(.Mui-disabled) {
    cursor: auto;
  }
}

.upload_file_box2 {
  border: 2px dashed #ddd;
  position: fixed;
  bottom: 200px;
  right: 10px;
  width: 400px;
  z-index: 9;

  p {
    font-size: 1rem !important;
    margin-bottom: 1rem;
  }

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root:hover:not(.Mui-disabled) {
    cursor: auto;
  }
}

.upload_file_box {
  border: 2px dashed #ddd;
  position: fixed;
  bottom: 59px;
  right: 10px;
  width: 400px;
  z-index: 9;
  margin: 10px 0px;

  p {
    font-size: 1rem !important;
    margin-bottom: 1rem;
  }

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root:hover:not(.Mui-disabled) {
    cursor: auto;
  }
}

.success_icon {
  color: #00684d;
  float: right;
  margin-left: 10px;
}

.cancel_icon {
  color: #f40404;
  float: right;
  margin-left: 10px;
}

.uploading_file {
  border: 1px dashed #00684d;
}

.upload_cancel {
  position: absolute !important;
  right: 0;
  top: 15px;
  color: #757575 !important;
  background: transparent !important;
  font-weight: bolder;

  svg {
    font-size: 24px !important;
    cursor: pointer;
  }
}

.progressbar {
  width: 20px !important;
  height: 20px !important;
  text-align: right;
  margin-left: 10px;
  // margin-top: 10px;
  // position: relative;
}

.file_name {
  display: flex;
  justify-content: space-between;

  p {
    padding: 10px 4px;
    font-size: 1rem !important;
  }

  h5 {
    font-size: 1rem !important;
  }

  svg {
    font-size: 24px;
    position: relative;
    top: 13px;
  }
}

/*====Responsive media===*/

@media screen and (max-width: 600px) {
  .report-carrd-title {
    text-align: left;
  }

  .template-download-border {
    margin-top: 1rem !important;
    border-left: 2px dashed #ddd;
  }

  .oms_reset_btn {
    margin-bottom: 10px !important;
  }

  .logo-img {
    max-width: 200px;
    height: auto;
    margin: 50px auto;
  }

  .upload_image_main th {
    width: 300px;
    /* overflow-x: hidden; */
    white-space: nowrap;
  }

  .store_text_margin_left {
    margin-left: -19px !important;
    padding: 5px !important;
  }

  .upload_image_main .css-188tcqr-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px !important;
  }

  .order_date {
    margin-left: 0%;
  }

  .center-model {
    width: 90% !important;
  }
}

.center-model {
  @include model;
  outline: none !important;
  width: 50%;
}

// MUI Data Table Styling

// .css-11mde6h-MuiPaper-root {
//   background-color: rgba(0, 0, 0, 0) !important;
//   box-shadow: none !important;
// }

// .css-1nrlq1o-MuiFormControl-root {
//   display: inline-flex !important;
//   -ms-flex-direction: column !important;
//   flex-direction: column !important;
//   position: relative !important;
//   min-width: 0 !important;
//   border: 0 !important;
//   vertical-align: top !important;
//   padding: 20px !important;
//   margin: 10px 15px !important;
// }

// MUI Data Table Styling

/*====Responsive Table===*/
.table_btn {
  display: flex !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  margin-right: 4px !important;
}

.responsive_table table {
  display: table;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.responsive_table tbody tr {
  text-align: center !important;
}

.responsive_table td {
  text-align: center !important;
}

.responsive_table th {
  text-align: center !important;
}

.responsive_table td {
  text-align: center !important;
}

// .tss-qbo1l6-MUIDataTableToolbar-actions button {
//   float: right;
// }
/*====Responsive Table===*/

.CEC-border-none {
  border: none;

  td {
    border: none !important;
  }
}

// .css-1ortluj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
//   color: #00684d !important;
//   // width: max-content!important;
//   background-color: white !important;
//   // padding-right: 4px!important;
// }
// .css-14f76fm-MuiButtonBase-root-MuiMenuItem-root {
//   min-height: auto;
//   font-size: 14px !important;
// }
// .css-ol7xnc-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
//   color: #00684d;
//   background-color: #fff !important;
// }

/*====Responsive media===*/

// Side Navigation Menu

.header-side-navbar .MuiPaper-elevation16 {
  width: 300px;
}

.header-side-navbar .MuiPaper-elevation16 span {
  font-size: 15px !important;
  color: #000;
}

.header-side-navbar div a {
  text-decoration: none;
}

.side-nav-bar-list {
  cursor: pointer;
}

.side-nav-bar-list:hover {
  background-color: #ededed;
}

.side-navbar-list-items li div {
  text-decoration: none !important;
  padding-left: 20px !important;
}

.side-navbar-list-items li:hover {
  background-color: #ededed;
}

.file-upload-btn:hover {
  cursor: pointer;
}

.side-nav-bar-close-section {
  height: 64px;
  align-items: center;
  background-color: #00684d;
  color: #ddd;
  font-size: 50px;
}

.side-nav-bar-close-section svg {
  cursor: pointer;
}

// Side Navigation Menu End

// Account journal CSS Starts
.text-center {
  text-align: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.verticle-middle {
  top: 50%;
  position: relative;
  transform: translate(0, -50%);
}

.detail-border {
  border: 1px solid #ccc;
}

.paymentReport-datepicker {
  display: flex !important;
  align-items: baseline;
  margin-top: 8px !important;
}

// Account journal CSS Ends

// .css-rqglhn-MuiTable-root {
//   display: table;
//   width: 100%;
//   border-collapse: inherit !important;
// }

.bg_danger {
  background-color: #df0000 !important;
  padding: 5px !important;
  // margin: 4px 0px 4px 38px !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
}

.unshipped {
  padding: 5px 0px !important;
  margin: 4px 0px !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  width: 130px;
  display: block !important;
}

.border_dashed {
  border-bottom: 2px dashed #000;
  margin: 13px 0px !important;
  padding: 10px 0px;
}

.no_cursor {
  cursor: auto !important;
}

// .oms_head_checkbox {
//   padding: 10px 0px !important;
// }
.order_date_checkbox span {
  display: inline-block;
  padding: 0;
  margin-right: 5px;
  margin-left: 7px;
}

.product_list_btn {
  float: right;
  margin-bottom: 30px !important;
}

// .css-1ex1afd-MuiTableCell-root {
//   vertical-align: baseline !important;
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
// }
.border_botttom {
  padding: 10px 0px;
}

.cz-order-detail-oms__textbox {
  input {
    height: auto !important;
  }
}

.oms-Shipment-table {
  .css-19gb7ar-MuiFormLabel-root-MuiInputLabel-root {
    line-height: 15px !important;
    margin-top: -5px;
    font-size: 12px !important;
  }

  .css-19i4bzq-MuiFormLabel-root-MuiInputLabel-root {
    top: -10px;
  }

  .css-11lq3yg-MuiGrid-root {
    justify-content: space-between;
  }

  .text-validation {
    color: $danger;
    margin-top: 2px;
    font-size: 12px;
    margin-left: 2px;
  }

  .css-1m0zcpu-MuiButtonBase-root-MuiButton-root {
    cursor: default;
  }

  //mobile view
  @media screen and (max-width: 700px) {
    .shipment-order-summary-detail {
      text-align: center;
      margin-right: 0px;
      margin-bottom: 10px;
    }

    .css-1ex1afd-MuiTableCell-root {
      vertical-align: middle !important;
    }

    .shipment-order-summary-detail div:nth-child(2)>div {
      justify-content: center;
      gap: 10px;

      div {
        width: 100%;
        justify-content: flex-start !important;
      }
    }

    .shipment-order-shipment {
      text-align: center;
    }

    .css-1gtfl7l {
      width: 90px;
    }

    .shipment-order-shipment div:nth-child(2)>div {
      justify-content: center;
      gap: 20px;

      div {
        width: 100%;
        justify-content: flex-start !important;
      }
    }

    .shipment-button-group {
      display: flex;
      gap: 20px;
    }

    .shipment-confirm-order {
      div:first-child div:first-child {
        gap: 0;
        text-align: center;
      }
    }

    .shipment-confirm-detail {
      width: 100%;

      div:nth-child(3) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        p {
          width: auto;
        }
      }
    }
  }

  // Tablet View
  @media screen and (max-width: 900px) {
    .shipment-order-summary-detail {
      width: 100%;
      text-align: center;
      margin-right: 0px;
      margin-bottom: 10px;
    }

    // .css-1ex1afd-MuiTableCell-root {
    //   vertical-align: middle !important;
    // }
    .shipment-order-summary-detail div:nth-child(2)>div {
      justify-content: space-around;
      text-align: left;
    }

    .shipment-order-shipment {
      width: 100%;
      text-align: center;
    }

    // .shipment-product-detail {
    //   td:nth-child(2) > div {
    //     flex-wrap: wrap;
    //   }
    // }

    .shipment-order-shipment div:nth-child(2)>div {
      justify-content: space-around;
      text-align: left;
    }

    .shipment-button-group {
      display: flex;
      gap: 20px;
    }

    // .shipment-product-table {
    //   display: block;
    //   overflow-x: scroll;
    // }
    .shipment-confirm-order>div:first-child>div:first-child {
      justify-content: space-between;
    }

    .shipment-confirm-order {
      width: 100%;
    }

    .shipment-confirm-product {
      width: 100%;
      margin-right: 0px;
    }

    .shipment-confirm-detail>div:nth-child(3) {
      flex-wrap: wrap;
      gap: 10px;
    }

    .confirm-shipment-button {
      justify-content: flex-end;

      button:first-child {
        margin-right: 10px;
      }
    }
  }

  .confirm-shipment-button {
    justify-content: flex-end;

    button:first-child {
      margin-right: 10px;
    }
  }

  // .css-1ex1afd-MuiTableCell-root {
  //   vertical-align: middle !important;
  // }
  // .shipment-product-table {
  //   thead {
  //     background-color: #f0f2f2;
  //     th {
  //       min-width: 35px;
  //     }
  //   td div {
  //     width:160px;
  //   }

  //     th:nth-child(2) {
  //       // text-align: center;
  //     }
  //   }
  // }
}

// #oms_confirm .css-6hp17o-MuiList-root-MuiMenu-list {
//   list-style: none;
//   margin: 0;
//   padding: 0;
//   position: relative;
//   padding-top: 8px;
//   padding-bottom: 8px;
//   outline: 0;
//   height: 200px;
// }

// .oms-Shipment-table {
//   // .css-19gb7ar-MuiFormLabel-root-MuiInputLabel-root {
//   //   line-height: 15px !important;
//   //   margin-top: -5px;
//   //   font-size: 12px !important;
//   // }
//   // .css-19i4bzq-MuiFormLabel-root-MuiInputLabel-root {
//   //   top: -10px;
//   // }
//   // .css-11lq3yg-MuiGrid-root {
//   //   justify-content: space-between;
//   // }
//   .text-validation {
//     color: $danger;
//     margin-top: 2px;
//     font-size: 12px;
//     margin-left: 2px;
//   }
//   // .css-1m0zcpu-MuiButtonBase-root-MuiButton-root {
//   //   cursor: default;
//   // }
//   //mobile view
//   @media screen and (max-width: 700px) {
//     .shipment-order-summary-detail {
//       text-align: center;
//       margin-right: 0px;
//       margin-bottom: 10px;
//     }
//     // .css-1ex1afd-MuiTableCell-root {
//     //   vertical-align: middle !important;
//     // }
//     .shipment-order-summary-detail div:nth-child(2) > div {
//       justify-content: center;
//       gap: 10px;
//       div {
//         width: 100%;
//         justify-content: flex-start !important;
//       }
//     }
//     .shipment-order-shipment {
//       text-align: center;
//     }
//     .css-1gtfl7l {
//       width: 90px;
//     }
//     .shipment-order-shipment div:nth-child(2) > div {
//       justify-content: center;
//       gap: 20px;
//       div {
//         width: 100%;
//         justify-content: flex-start !important;
//       }
//     }
//     .shipment-button-group {
//       display: flex;
//       gap: 20px;
//     }

//     .shipment-confirm-order {
//       div:first-child div:first-child {
//         gap: 0;
//         text-align: center;
//       }
//     }
//     .shipment-confirm-detail {
//       width: 100%;
//       div:nth-child(3) {
//         flex-wrap: wrap;
//         justify-content: center;
//         gap: 10px;
//         p {
//           width: auto;
//         }
//       }
//     }
//   }
// }

.oms-shipment-tabs {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

//CAncellation css
.CZ-Cancellation-Error {
  display: flex;
  margin-right: 10px;
}

.radio_button_box {
  display: flex !important;
}

.paymentReport {
  padding-left: 2;
  padding-right: 2;

  .valuefrom,
  .valueto {
    background-color: white;
    margin-right: 10px !important;
  }

  @media screen and (max-width: 880px) {
    .paymentReports-product-table {
      display: block !important;
      overflow-x: scroll;
    }
  }

  .settlement,
  .pastnum,
  .paymentReport-dropdown {
    height: 55;
    align-items: center;
    background-color: #ffffff;
  }

  .paymentReport-table-header {
    margin-top: 1;
    background-color: #ffffff;
  }
}

.pr_search_bar {
  display: flex;
  align-items: center;
}

.pr_download_btn {
  @include centerFlex;
  align-items: center;
  margin-left: 20px;
}

.sales_list_header {
  @media screen and (min-width: 1200px) {
    @include centerFlex;
  }

  align-items: center;
}

.pr-table {
  display: block;
  overflow-x: auto;
}

.Order_id_link {
  color: rgb(0, 104, 77) !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.reset_btn {
  display: flex;
  justify-content: flex-start;
}

.submit_btn {
  @media screen and (min-width: 1200px) {
    @include centerFlex;
  }

  @media screen and (max-width: 1199px) {
    padding-right: 100px;
  }
}

.sales_table_negative_quantity {
  color: red !important;
  border-bottom: none !important;
}

.sr_history_filter {
  margin-right: 40px !important;
  margin-bottom: 20px !important;
}

.multiple-shipment-others-input-field input {
  height: 0.4375em !important;
}

.multiple-shipment-others-input-field-label label {
  top: -0.4625em !important;
}

.sales_history_negative_quantity_red {
  color: red !important;
  border: hidden !important;
  text-align: right;
}

.sales_history_negative_quantity_black {
  color: black !important;
  border: hidden !important;
  text-align: right;
}

.image_upload_box {
  height: 615px;
  // height: 90vh;
  // overflow-y: auto;
  overflow-x: hidden;
  border: 1px #ddd double;
  box-shadow: none !important;
  background-color: rgba(238, 238, 238) !important;
}

.link_color {
  text-decoration: none;
  border: none !important;
  color: #00684d !important;
}

.image_upload_save_btn {
  float: right;
}

.timer-align {
  float: right;
  margin-right: 0 !important;
}

// .image_uplaod_product_name{
//   @include centerFlex;
//   margin-top: 30px !important;
//   margin-bottom: 20px;
// }
.image-upload-search {
  padding-top: 0px !important;
}

// .image_upload_container{
//   max-height: 59vh !important;
//   overflow: hidden;

// }
.image_data_inner_container {
  // overflow: auto;
  // Same height as Image Box
  max-height: 615px;
}

.image_upload_box_container {
  padding: 0px !important;
}

.image_upload_pagination {
  display: flex !important;
  justify-content: flex-start !important;
}

.product_image_upload {
  font-size: 80px !important;
  color: #bdbdbd;
}

.upload_image_container {
  @include centerFlex;
  position: relative;
  height: auto !important;
  box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important
}

.setWidth {
  width: 93px;
}

.featuredImage {
  font-size: 40px !important;
  color: #bdbdbd;
}

.productImageNo {
  @include centerFlex;
  justify-content: center;
  font-weight: 400 !important;
}

.cancel_upload_img {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 18px !important;
  color: rgba(255, 0, 0, 0.6);
  cursor: pointer;
  z-index: 1;
  right: 0px;
  top: 0px;
}

.disable_cancel_upload_img {
  color: rgb(159 140 140 / 60%);
}

.image_modal {
  // height: 31vh;
  // overflow-y: auto;
  // overflow-x: hidden;
  width: 250px;
  height: auto;
}

.image_modal .css-14uw8wo-MuiGrid-root {
  padding: 0px !important;
  padding-top: 16px !important
}

.file_uploader_in_image_upload {
  min-width: 0px !important;
  min-height: 100px;
  width: 100px;
  height: 100 px !important;
  margin: 15px 0;
}

.file_uploader_in_image_upload .file-types {
  visibility: hidden;
}

.imageProductSize {
  width: 70px !important;
  height: 70px !important;
  // margin: 15px 0;
  position: relative;
  z-index: 0;
}

.uploadingimageProductSize {
  width: 100px !important;
  height: 100px !important;
  // margin: 15px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageLoader {
  width: 100px !important;
  height: 70px !important;
  // margin: 15px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageUploadModal {
  top: 30% !important;
  left: 35% !important;
  right: 35% !important;
}

.modal_main_image {
  height: 25vh !important;
  margin-bottom: 10px;
}

.bottom_img_container__box {
  height: 5vh;
  width: 2vw;
}

.modalImageProductSize {
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
}

.imageUploadModal .MuiGrid-container {
  padding: 0px !important;
}

.modalMainImageProductSize {
  width: 24vh !important;
  cursor: pointer;
}

.uploadingImage {
  position: absolute;
  top: 35%;
  background: white;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  font-size: 10px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: -moz-available;
}

.Image_upload_popover {
  top: -100px !important;
}

.bottom_img_container {
  padding: 5px !important;
  margin: 4px;
}

.upload-btn-grid {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
}

.file-upload-btn {
  font-size: 2.5rem !important;
}

.oms-date-picker {
  display: flex !important;
}

.oms_date_picker_grid {
  margin-right: 12px;
}

.oms_submit-btn {
  max-height: 38px !important;
}

.oms_reset_btn {
  width: 88px;
}

.oms_date_picker_grid .css-19i4bzq-MuiFormLabel-root-MuiInputLabel-root {
  top: -9px !important;
}

.oms-date-picker-default {
  // max-height: 40px !important;
}

.oms_date_picker_grid .MuiInputBase-input {
  max-height: 20px !important;
  width: 100px !important;
}

.refund_order_img {
  width: 100px;
  margin-top: 51px;
}

.image_upload_rules {
  font-size: 10px !important;
  color: #7d827d;
}

.refund-checkbox {
  display: flex !important;
  justify-content: flex-end !important;
  flex-direction: column !important;
}

.file_uploader_in_image_upload .kFhUBM span {
  text-decoration: none;
}

.file_upload_reset_btn {
  margin-top: 16px !important;
}

.file_upload_replace_btn {
  margin-right: 15px !important;
  margin-top: 16px !important;
}

.file_upload_btn_box {
  display: inline-flex;
}

// .file_uploader_in_image_upload .sc-fqkvVR p,span{
//   font-size: 11px !important;
// }
.instruction_popover_box {
  width: 563px;
  height: auto;
  padding: 20px;
}

.textarea {
  width: 96% !important;
  height: 50% !important;
  border: 1px solid #8a7e7e;
}

.image_upload_instruction_icon_box {
  position: absolute;
  top: 27%;
  right: 0%;
}

.heading_grid {
  position: relative;
}

.image_upload_instruction_icon {
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 8px;
}

.about_uploading_image {
  display: flex;
  align-items: center;
}

.appMinHeight {
  min-height: 77vh;

  @media screen and (min-height: 700px) {
    min-height: 80vh;
  }

  @media screen and (min-height: 800px) {
    min-height: 81vh;
  }

  @media screen and (min-height: 900px) {
    min-height: 83vh;
  }

  @media screen and (min-height: 1000px) {
    min-height: 85vh;

  }

  @media screen and (min-height: 1100px) {
    min-height: 87vh;
  }

  @media screen and (min-height: 1200px) {
    min-height: 88vh;
  }
}

.validation-text {
  color: #ff000f;
}

.image_uploader_box {
  height: 70px;
  width: 75px;
  border: 2px dotted rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  object-fit: cover;
  overflow: hidden;
  // padding: 8px 23px 8px 23px;
}

.disable_image_uploader_box {
  border: 2px dashed rgb(180 186 194);
}

.image_uploader_text {
  font-size: 11px !important;
  align-items: center !important;
  color: green;
}

.liveImageColor {
  color: green;
}

.sortingIconPosition {
  color: #1c1c1c;
  font-size: 23px !important;
}

.swapVertIcon {
  color: #c1c1c1 !important;
}

.image_upload_table {
  margin: 0px !important;

  th {
    position: static !important;
  }
}

// .image_upload_table .tss-1cdcmys-MUIDataTable-responsiveBase{
//   height: 57vh !important;
//   // width: 704px;
//   // max-width: 104%;

// }
.progressPercent {
  position: absolute;
  top: 63%;
  left: 12%;
}

.product_list_download {
  width: 100% !important;
}

.margin_left {
  margin-left: 16px !important;
}

.padding_1rem {
  padding: 1rem !important;
}

.background_color_red {
  background-color: #df0000 !important;
}

.textform_capitalize {
  text-transform: capitalize !important;
}

.margin_right {
  margin-right: 8px !important;
}

.store_invite_btn {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.margin_vertical {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.margin_bottom {
  margin-bottom: 8px !important;
}

.header-margin-bottom {
  margin-bottom: 20px !important;
}

.msg_position {
  top: 0% !important;
}

.disableBackground {
  background-color: rgba(0, 0, 0, 0.12) !important
}

.display-flex-with-space {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.white-space {
  white-space: nowrap;
}

.timer-background {
  background: #d9d8d8;
  padding: 9px;
  border-radius: 5px;
  font-size: 12px !important;
  // margin-right: 15px;
}

.bold {
  font-weight: bold;
}

.notification_msg {
  background-color: #F29810 !important;
  height: 100% !important;
  width: 100%;
  font-size: 14px !important;
}

.word_wrap {
  word-wrap: break-word;
  /* Allows words to be broken at arbitrary points */
  white-space: normal;
  /* Allows text to wrap to new lines */
}

.msg_position {
  top: 0% !important;
  background-color: #F29810 !important;
  width: 100%;
  position: absolute;
  font-size: 14px !important;
}

.filter_icon {
  background-color: rgb(0, 72, 53);
  color: #fff;
  margin-top: 7px;
  cursor: pointer;
  float: right;
}

.product_list_Download_button {
  position: absolute !important;
  z-index: 99;
}

.margin_csv_download_product {
  margin-top: 57px !important;
}

.margin_excel_download_product {
  margin-top: 1px !important;
}

.product_list_inst_icon {
  display: flex;
  align-items: center;
  justify-content: end;

}

.popover_info_box {
  margin-top: -35px;
}

.internet_connection_snackbar .MuiSnackbar-root {
  position: fixed;
  top: 8%;
}

.add_product_btn {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .add_product_btn {
    width: auto;
  }
}

// Product Details page adding css for textarea 

.product_text_area textarea {
  overflow: hidden;
  white-space: nowrap;
  resize: none;
}

.product_text_area:hover .input_scroll>textarea {
  overflow-x: scroll !important;
  overflow-y: hidden;
  resize: none;
  white-space: nowrap;
  background: #fff;
}

.product_text_area_with_out_hover textarea {
  overflow: hidden;
  white-space: nowrap;
  resize: none;
}

.product_text_area_with_out_hover .input_scroll>textarea {
  overflow-x: scroll !important;
  overflow-y: hidden;
  resize: none;
  white-space: nowrap;
  background: #fff;
}

.input_scroll>textarea::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* Added 'px' unit */
  background-color: #f5f5f5;
  height: 5px;
}

.input_scroll>textarea::-webkit-scrollbar {
  width: 1px !important;
  height: 5px;
  background-color: #fff4f4;
}

.input_scroll>textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ada2a259;
  height: 5px;
}

.input_scroll>textarea::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  height: 5px;
}

.center-model-information {
  @include model;
  width: 50% !important;
  text-align: left !important;
  max-height: 60%;
  overflow: auto;
  outline: none;
  border: none;
}

.notification-modal-description {
  margin-top: 2px !important;
  padding: 10px;
  white-space: pre-wrap;
}

.system-update-read-more {
  cursor: pointer !important;
  font-size: 12px !important;
  color: #00684D !important;
  font: inherit !important;
  margin-top: 63px;
}

.clampedText {
  overflow: hidden;
  line-height: 1.8;
  /* Adjust based on your font size */
  white-space: pre-line;
  /* Keeps the line breaks (\n) */
  max-height: 100px;
}

.image_grid_container {
  background-color: rgba(238, 238, 238, 0.74);
  padding-bottom: 0px !important;
  padding-top: 52px;
  justify-content: space-around;
}